import React from "react";
import dynamic from "next/dynamic";

const ComponentTypes = {
  header: dynamic(() => import("./CmsElements/Header/Header")),
  textpic: dynamic(() => import("./CmsElements/Textpic/Textpic")),
  textmedia: dynamic(() => import("./CmsElements/Textpic/Textpic")),
  text: dynamic(() => import("./CmsElements/Text/Text")),
  image: dynamic(() => import("./CmsElements/Images/Images")),
  shortcut: dynamic(() => import("./CmsElements/Shortcut/Shortcut")),
  html: dynamic(() => import("./CmsElements/Html/Html")),
  bullets: dynamic(() => import("./CmsElements/Bullets/Bullets")),
  div: dynamic(() => import("./CmsElements/Div/Div")),
  table: dynamic(() => import("./CmsElements/Table/Table")),
  uploads: dynamic(() => import("./CmsElements/Uploads/Uploads")),
  menu_pages: dynamic(() => import("./CmsElements/Menu/Menu")),
  container_100: dynamic(() => import("./CmsElements/Container100")),
  container_50_50: dynamic(() => import("./CmsElements/Container50")),
  container_66_33: dynamic(() => import("./CmsElements/Container66_33")),
  container_33_66: dynamic(() => import("./CmsElements/Container33_66")),
  container_33_33_33: dynamic(() => import("./CmsElements/Container33_33_33")),
  mask_jobs_widget: dynamic(() => import("./CmsElements/jobs/JobsWidget")),
  mask_articlelist_teaser: dynamic(() =>
    import("./CmsElements/ArticleListTeaser")
  ),
  mask_job_searchform_widget: dynamic(() =>
    import("./CmsElements/jobs/JobSearchFormWidget")
  ),
  mask_accordion: dynamic(() => import("./CmsElements/Accordion/Accordion")),
  mask_benefits_widget: dynamic(() =>
    import("./CmsElements/jobs/EmployerBenefitsWidget")
  ),
  mask_employer_teaser: dynamic(() =>
    import("./CmsElements/jobs/EmployerTeaserWidget/EmployerTeaserWidget")
  ),
  mask_employer_contacts: dynamic(() =>
    import("./CmsElements/jobs/EmployerContactsWidget")
  ),
  mask_employer_locations: dynamic(() =>
    import("./CmsElements/jobs/EmployerLocationsWidget")
  ),
  mask_map: dynamic(() => import("./CmsElements/Map/Map")),
  mask_uni_list: dynamic(() => import("./CmsElements/UniList/UniList")),
  mask_range_slider: dynamic(() =>
    import("./CmsElements/RangeSlider/RangeSlider")
  ),
  mask_testimonials: dynamic(() =>
    import("./CmsElements/Testimonials/Testimonials")
  ),
  mask_quick_search_widget: dynamic(() =>
    import("./CmsElements/jobs/QuickSearchWidget")
  ),
  mask_adglare: dynamic(() => import("./CmsElements/AdGlare/AdGlare")),
  mask_employer_badges: dynamic(() =>
    import("./CmsElements/jobs/EmployerBadges")
  ),
  mask_employer_references: dynamic(() =>
    import("./CmsElements/jobs/EmployerReferences")
  ),
  mask_pie_chart: dynamic(() =>
    import(
      /* webpackChunkName: "contents_pie_chart" */ "./CmsElements/PieChart/PieChart"
    )
  ),
  mask_uni_teaser: dynamic(() => import("./CmsElements/UniTeaser/UniTeaser")),
  mask_embetty: dynamic(() => import("./CmsElements/Embetty/Embetty")),
  mask_slider: dynamic(() => import("./CmsElements/Slider/Slider")),
  mask_rooms_tour: dynamic(() => import("./CmsElements/RoomsTour/RoomsTour")),
  mask_custom_chart: dynamic(() => import("./CmsElements/CustomChart")),
  mask_employer_podcast_episodes_imr: dynamic(() =>
    import("./CmsElements/ImrPodcastPlayer")
  ),
  mask_eventslist: dynamic(() => import("./CmsElements/EventsList")),
  mask_employerjobformatrequest: dynamic(() =>
    import("./CmsElements/jobs/EmployerSubscribeFormatForm")
  ),
  mask_registration_form: dynamic(() =>
    import("./CmsElements/UserRegistrationForm")
  ),
  mask_employer_employee_transfers: dynamic(() =>
    import("./CmsElements/jobs/EmployerTransfers")
  ),
  mask_employer_registration_form: dynamic(() =>
    import("./CmsElements/EmployerRegistrationForm")
  ),
  mask_employer_articles: dynamic(() =>
    import("./CmsElements/EmployerArticles")
  ),
  mask_employer_social_posts: dynamic(() =>
    import("./CmsElements/jobs/EmployerSocialPosts")
  ),
  mask_image_with_slogan: dynamic(() =>
    import("./CmsElements/ImageWithSlogan")
  ),
  eine_spalte_100: dynamic(() => import("./CmsElements/Container100")),
  eine_spalte_80: dynamic(() => import("./CmsElements/Container100")),
  zwei_spalten_50_50: dynamic(() => import("./CmsElements/Container50")),
  zwei_spalten_marginal_links: dynamic(() =>
    import("./CmsElements/Container33_66")
  ),
  zwei_spalten_marginal_links_33_66: dynamic(() =>
    import("./CmsElements/Container33_66")
  ),
  zwei_spalten_marginal_rechts: dynamic(() =>
    import("./CmsElements/Container66_33")
  ),
  zwei_spalten_marginal_rechts_66_33: dynamic(() =>
    import("./CmsElements/Container66_33")
  ),
  drei_spalten: dynamic(() => import("./CmsElements/Container33_33_33")),
  uni_guide_header_element_66_33: dynamic(() =>
    import("./CmsElements/ContainerUniHeader")
  ),
  ltoyourist_pi1: dynamic(() => import("./CmsElements/YouristPi1")),
  ltoyourist_pi2: dynamic(() => import("./CmsElements/YouristPi2")),
  mask_request_form_with_options: dynamic(() =>
    import("./CmsElements/RequestFormWithOptions")
  ),
};

const Components = (contentItem, pos, additionalProps = {}) => {
  let cType = contentItem.CType;

  if (cType === "gridelements_pi1") {
    cType = contentItem.properties.containerLayout;
  } else if (cType === "list") {
    cType = contentItem.list_type;
  }

  if (typeof ComponentTypes[cType] !== "undefined") {
    return React.createElement(ComponentTypes[cType], {
      key: contentItem.id,
      id: contentItem.id,
      ceData: contentItem,
      ...additionalProps,
    });
  }

  return React.createElement(
    () => (
      <div key={"not_exists_" + pos}>
        The component {contentItem.CType} has not been created yet.
      </div>
    ),
    { key: contentItem.id }
  );
};

export default Components;
